import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(){
    if(localStorage.getItem('token')){
      localStorage.removeItem('token')
      localStorage.removeItem('user');
      window.location.reload
    }

  }
  title = 'jago-website';

  whatsappNumber: string ="https://api.whatsapp.com/send?phone=593984303924";
}
